<template>
  <v-container>
    <v-row no-gutters>
      <v-col md="12" class="pa-4">
        <p class="text-h4 mb-0">Result LIST</p>
        <!--Todo:<br>
        Filter by match participated<br>
        Show win or loss<br>
        Show bet ammounts<br>-->
      </v-col>
      <v-col v-for="invite of resultList" :key="invite.gameId">
              <past-tournament :data="invite" />
        <v-list-item two-line>
          <v-list-item-content>
            <span v-if="isBetter(invite.gameId)">YOU BETTED HERE<br></span>
            <v-list-item-title>gameId: <router-link :to="`/invite/` + invite.gameId">{{ invite.gameId }}</router-link></v-list-item-title>
            <v-list-item-subtitle @click="logInfo(invite,invite.gameId)">Sender: {{ invite.sender }}</v-list-item-subtitle>
            <v-btn v-if="canCash(invite.gameId)" @click="logInfo(invite,invite.gameId)">CASH OUT</v-btn>
            <!--{{invite.payout}}<br>
            {{invite.odds}}<br>Winners:<br>{{getWinners(invite)}}-->
          </v-list-item-content>
        </v-list-item>
        <v-divider style="margin-left: 15px; width: 640px" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    mapState,
    mapGetters,
    mapActions,
  } from 'vuex'
  import PastTournament from '../../components/featured/gamestreams/PastTournament.vue'
  export default {
    name: 'Partners',
    components: {
      PastTournament,
    },
    data () {
      return {
        matchIds: [],
        myLastMatches: [],
        partners: {},
        results: null,
      }
    },
    computed: {
      ...mapGetters('rpc', ['invites']),
      ...mapState('rpc', ['address', 'web3', 'connected']),
      ...mapState('wallet', ['platformContract', 'contract']),
      ...mapState('platform', ['mainHost']),
      resultList () {
        var list = []
        for (var inv of Object.values(this.invites)) {
          if (inv.state === 2) list.push(inv)
        }
        return list
      },
    },
    async mounted () {
      // this.results = (await this.$http.get(this.mainHost + '/results')).data
      // results
      // this.loadResults()
    },
    methods: {
      canCash (gameId) {
        return true
      },
      isBetter (gameId) {
        return this.myLastMatches.includes(gameId)
      },
      doCashout (gameId) {
        var c = this.collectReward(gameId)
        return c
      },
      getWinners (invite) {
        var winners = []
        for (var player of invite.winners) {
          var nChop = 1
          while (player.substr(-nChop, 1) === '0') {
            nChop++
          }
          player = this.web3.utils.toAscii(player.substr(0, player.length - nChop + 1))
          winners.push(player)
        }
        return winners
      },
      async loadResults () {
        if (this.address) {
          var r2 = await this.getLastMatchIdsBettedByBettor({ creator: this.address, count: 20 })
          this.myLastMatches = r2
        }
        var results = {}

        var r = await this.getLastMatchs(20)
        this.matchIds = r
        for (var gameId of r) {
          try {
            var info = await this.getOracleMatchState(gameId)
            // console.log('mi', gameId, info.matchState, info)
            if (info.matchState === '2') { // ok result..
              results[gameId] = info
              // console.log('agi', info)
              info.payout = await this.getPayoutValue(gameId)
              try {
                info.odds = await this.getMatchOdds(gameId)
              } catch (err) {
                //
              }

              console.log('xxxxgi', info, this.myLastMatches)
              if (this.address && this.myLastMatches.includes(gameId)) { // check if cashout is available
                var bet = await this.getBet({ address: this.address, gameId })
                console.log('cancash?', bet)
                if (bet) {
                  info.canCash = bet.resolved === false
                }
              }
            }
          } catch (err) {
            //
          }
        }
        this.results = results
        console.log('lr', r)
      },
      ...mapActions('rpc', ['getOracleMatchState', 'getMatch', 'getBet', 'getLastMatchs', 'getPayoutValue', 'getMatchOdds',
                            'collectReward', 'getLastMatchIdsBettedByBettor']),
      async logInfo (invite, gameId) {
        var info = await this.getMatch(gameId)
        console.log('i', invite, gameId, info)
        var bet = this.address ? await this.getBet({ address: this.address, gameId }) : null
        if (bet) {
          if (bet.resolved === false) {
            // ask to resolve?
            console.log('do resolve!')
            var c = this.collectReward(gameId)
            console.log('create match result', c)
          } else {
            invite.cashed = true
          }
        }
        console.log('log', info, bet)
      },
      ...mapActions('wallet', ['sendMsg']),
      ...mapActions('platform', ['getInvites']),
    },
  }
</script>
