<template>
  <v-container>
    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="past" style="height: 101px;">
      <v-col class="d-flex flex-row justify-space-around align-center mb-0" md="9">
        <v-avatar
          size="64"
          color="brown"
        >
          <v-img
            :src="address ? mainHost + `/avatar?addr=`: 'https://picsum.photos/id/237/200/300'"
            alt="Avatar"
          />
        </v-avatar>
        <p class="d-flex flex-column justify-center align-start text-h6 ml-3 mb-0">{{loosers}}<span class="font-weight-thin text-caption">12 people</span></p>
        <v-divider
          class="d-flex justify-center"
          vertical
          inset
          style="transform: rotate(24deg);"
        />
        <router-link class="white--text" :to="`/invite/`">
          <p class="mb-0 align-center" style="font-size: 24px;color: #9da3ac;font-weight: 500;">
            18:6
          </p>
        </router-link>
        <v-divider
          class="d-flex justify-center"
          vertical
          inset
          style="transform: rotate(24deg)"
        />
        <v-avatar
          size="64"
          color="brown"
        >
          <v-img
            :src="address ? mainHost + `/avatar?addr=`: 'https://picsum.photos/id/237/200/300'"
            alt="Avatar"
          />
        </v-avatar>
        <p class="d-flex flex-column justify-center align-start text-h6 mr-3 mb-0">{{data.winners}}<span class="font-weight-thin text-caption">12 people</span></p>
      </v-col>
      <v-col class="d-flex justify-end pa-0 ma-0" md="3">
        <div id="shadow" />
        <p class="ma-0 pl-15 d-flex justify-center align-center text-h6 px-4 win-layer">
          <span class="d-flex flex-column"><v-icon color="#ffd800">mdi-crown</v-icon> WIN</span>
        </p>
      </v-col>
    </v-row>

    <v-row v-else class="past">
      <v-col class="">
        <v-card class="pa-3 rounded-xl d-flex justify-center align-center flex-column winner">
          <v-avatar
            size="64"
            color="brown"
          >
            <v-img
              :src="address ? mainHost + `/avatar?addr=`: 'https://picsum.photos/id/237/200/300'"
              lazy-src="https://picsum.photos/id/237/200/300"
              alt="Avatar"
            />
          </v-avatar>

          <p class="d-flex flex-column justify-center align-center text-h6 mt-2 mb-0">QWERT<span class="font-weight-thin text-caption">12 people</span></p>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column justify-center align-center" cols="3">
        <router-link class="white--text" :to="`/invite/`">
          <p class="mb-0 align-center" style="font-size: 24px;color: #9da3ac;font-weight: 500;">
            <span>18</span>:<span>6</span>
          </p>
        </router-link>
      </v-col>
      <v-col class="">
        <v-card class="pa-3 rounded-xl d-flex justify-center align-center flex-column ">
          <v-avatar
            size="64"
            color="brown"
          >
            <v-img
              :src="address ? mainHost + `/avatar?addr=`: 'https://picsum.photos/id/237/200/300'"
              alt="Avatar"
            />
          </v-avatar>

          <p class="d-flex flex-column justify-center align-center text-h6 mt-2 mb-0">QWERT<span class="font-weight-thin text-caption">12 people</span></p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'PastTournamentCard',
    props: {
      data: {
        type: Object,
      },
    },
    data () {
      return {
        info: {},
        loosers: [],
      }
    },
    async mounted () {
      var bets = await this.getBets(this.data.gameId)
      var l = []
      for (var b of bets) {
        if (!this.data.winners.includes(b.player)) l.push(b.player)
      }
      this.loosers = l
    },
    computed: {
      ...mapState('rpc', ['address']),
      ...mapState('wallet', ['platformContract', 'contract', 'web3']),
      ...mapState('platform', ['invites', 'mainHost']),
    },
    methods: {
      ...mapActions('rpc', ['getBets']),
    },
  }
</script>

<style scoped>
  .past {
    border-radius: 25px;
    filter: drop-shadow(0px 2px 13.5px rgba(0,0,0,0.36));
    background-color: #2a2e34;
  }

  .win-layer {
    border-radius: 10px;
    width: 143px;
    height: 101px;
    border-radius: 10px;
    background-image: linear-gradient(90deg, #f5773b 0%, #cc4c0f 99%);
    -webkit-clip-path: polygon(46% 0, 100% 0, 100% 100%, 10% 100%);
    clip-path: polygon(46% 0, 100% 0, 100% 100%, 10% 100%);
    opacity: 100%;
  }

  #shadow {
    position: absolute;
    z-index: -1;
    content: "";
    width: 143px;
    height: 101px;
    border-radius: 10px;
    background-image: linear-gradient(90deg, #f5773b 0%, #cc4c0f 99%);
    opacity: 50%;
    right: 27px;
    -webkit-clip-path: polygon(56% 0, 100% 0, 100% 100%, 20% 100%);
    clip-path: polygon(56% 0, 100% 0, 100% 100%, 20% 100%);;
  }

  .winner {
    background-image: linear-gradient(90deg, #f5773b 0%, #cc4c0f 99%);
  }

  .score-winner {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
</style>
